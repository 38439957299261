import { render, staticRenderFns } from "./SingleStudent.vue?vue&type=template&id=7866d500&scoped=true"
import script from "./SingleStudent.vue?vue&type=script&lang=js"
export * from "./SingleStudent.vue?vue&type=script&lang=js"
import style0 from "./SingleStudent.vue?vue&type=style&index=0&id=7866d500&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7866d500",
  null
  
)

export default component.exports